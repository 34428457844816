<template>
  <v-app-bar height="100" id="toolbar" app :color="scrolled ? '#000000' : 'transparent'" elevation="0">
    <v-container>
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-toolbar-title @click="$router.push('/home')">
            <v-img width="100" src="../assets/logo.png"></v-img>
          </v-toolbar-title>
        </div>

        <div v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" class="d-flex align-center">
          <v-menu v-for="{ title, path, index, items } in navItems" :key="index" offset-y open-on-hover
            :close-on-content-click="false" :close-on-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :to="path" text v-bind="attrs" v-on="on" style="color: #fafafa">
                {{ title }}
              </v-btn>
            </template>

            <v-list v-if="items" no-action dense>
              <v-list-item-group>
                <v-list-item v-for="(item, i) in items" :key="i" @click="navigateBodykit(item)">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <!-- <v-list v-if="items" no-action dense>
              <v-list-group v-for="({ title, subItems }, i) in items" :key="i">
                <template v-slot:activator>
                  <v-list-item-title>{{ title }}</v-list-item-title>
                </template>

                <v-list-item class="ml-5" v-for="({ title, path }, i) in subItems" :key="i" :to="path">
                  <v-list-item-title>{{ title }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list> -->
          </v-menu>

          <div class="" @click="searchDialog = true">
            <v-btn dark text>
              <v-icon left> mdi mdi-magnify </v-icon>
              Search
            </v-btn>

            <!-- <v-autocomplete dark v-model="value" :items="items" item-text="name" item-value="path" dense solo-inverted
              placeholder="Search" prepend-inner-icon="mdi mdi-magnify" append-icon="" :clearable="true" search-input
              @change="redirectTo">
            </v-autocomplete> -->

            <!-- <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip v-bind="attr" :input-value="selected" color="blue-grey" class="white--text" v-on="on">
                <v-icon left>
                  mdi-bitcoin
                </v-icon>
                <span v-text="item.name"></span>
              </v-chip>
            </template> -->

            <!-- <v-text-field dark solo dense placeholder="Search" prepend-inner-icon="mdi mdi-magnify"></v-text-field> -->
          </div>
        </div>

        <div>
          <v-btn v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" icon @click="searchDialog = true">
            <v-icon color="#fafafa">mdi mdi-magnify</v-icon>
          </v-btn>

          <v-btn v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" icon @click="menuDialog = true">
            <v-icon color="#fafafa">mdi mdi-menu</v-icon>
          </v-btn>
        </div>

        <!-- <div v-if="$vuetify.breakpoint.xs" class="d-flex align-center">
          <v-menu v-for="{ title, path, index, items } in navItems" :key="index" offset-y open-on-hover
            :close-on-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :to="path" text v-bind="attrs" v-on="on" style="color: #fafafa">
                {{ title }}
              </v-btn>
            </template>
            <v-list v-if="items" no-action dense>
              <v-list-group v-for="({ title, subItems }, i) in items" :key="i">
                <template v-slot:activator>
                  <v-list-item-title>{{ title }}</v-list-item-title>
                </template>

                <v-list-item class="ml-5" v-for="({ title, path }, i) in subItems" :key="i" :to="path">
                  <v-list-item-title>{{ title }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-menu>
        </div> -->
      </div>
    </v-container>

    <v-dialog dark v-model="searchDialog" width="600">
      <v-card style="border-radius: 5px" class="px-5">
        <v-row>
          <v-col v-for="item in filterItems" :key="item.value">
            <v-checkbox v-model="filters" :label="item.label" :value="item.value" hide-details
              @click="debouncedSearch"></v-checkbox>
          </v-col>
        </v-row>
        <div>
          <v-text-field outlined placeholder="Looking for..." prepend-inner-icon="mdi mdi-magnify" v-model="searchTerm"
            @input="debouncedSearch"></v-text-field>
        </div>
        <v-card-text>
          <div v-if="!searchTerm" class="d-flex flex-column align-center justify-center">
            <div>
              <v-icon size="150"> mdi mdi-text-box-search-outline </v-icon>
            </div>

            <div class="mt-15 mb-5">
              <span style="font-size: 14px">Your search results will appear here</span>
            </div>
          </div>
          <div v-else>

            <div v-if="loading" class="d-flex flex-column align-center justify-center text-center">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>

            <div v-else>
              <v-list v-if="searchResults.length > 0" subheader>
                <div v-for="(item, i) in searchResults" :key="i">
                  <v-subheader>{{ item.title }}</v-subheader>
                  <v-list-item v-for="(result, i) in item.items" :key="i" @click="searchItemClicked(result, item.title)">
                    <v-list-item-content>
                      <v-list-item-title>{{ result.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>

              <div v-else>
                <v-col cols="12">
                  <div class="d-flex flex-column align-center justify-center text-center">
                    <h1 style="color: white;">No Results Found</h1>
                  </div>
                </v-col>
              </div>

            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="searchDialog = false" text>close</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- mobile navigation bar -->
    <v-dialog dark v-model="menuDialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card style="border-radius: 0px">
        <div class="m-2 d-flex align-end">
          <v-spacer></v-spacer>
          <v-btn small fab icon @click="menuDialog = false">
            <v-icon size="20" color="#909090">mdi-close</v-icon>
          </v-btn>
        </div>

        <!-- <v-container>
          <v-text-field dark solo dense placeholder="Search" prepend-inner-icon="mdi mdi-magnify"></v-text-field>
        </v-container> -->

        <!-- <div v-if="!isAuthenticated" class="m-2 d-flex align-center">
          <v-btn @click="menuDialog = false" outlined class="white--text mr-2" to="/signup" small
            style="border: 1px solid #fafafa; border-radius: 20px;">
            Sign up
          </v-btn>

          <v-btn @click="menuDialog = false" text class="white--text" to="/signin" small style="border-radius: 20px;">
            Log in
          </v-btn>
        </div> -->

        <!-- <div>
            <v-text-field outlined x-small placeholder="Search Coin" prepend-inner-icon="mdi-magnify"></v-text-field>
          </div> -->

        <template v-for="{ title, path, index, items } in navItems">
          <v-list-group v-if="items && items.length > 0" :key="index" no-action>

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="(item, i) in items" :key="i" @click="navigateBodykit(item)">
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-group v-for="(item, i) in items" :key="i" @click="navigateBodykit(item)">
              <template v-slot:activator>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>

              <v-list-item @click="menuDialog = false" class="ml-5" v-for="({ title, path }, i) in subItems" :key="i"
                :to="path">
                <v-list-item-title><small>{{ title }}</small>
                </v-list-item-title>
              </v-list-item>
            </v-list-group> -->
          </v-list-group>

          <v-list-item @click="menuDialog = false" style="flex: none !important; cursor: pointer" :to="path" v-else
            :key="title">
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import debounce from "lodash/debounce";
import { fetchSearch } from "../services/searchServices";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchDialog: false,
      items: [
        { name: "Primal Beast", path: "/project/primalbeast" },
        { name: "Liona", path: "/project/liona" },
        { name: "Crypto", path: "/project/crypto" },
        { name: "Blaze", path: "/project/blaze" },
      ],
      values: ["foo", "bar"],
      value: null,
      scrolled: false,
      menuDialog: false,
      searchTerm: "",
      navItems: [
        {
          title: "Home",
          active: false,
          path: "/home",
        },

        {
          title: "Body Kits",
          active: false,
          items: [
            {
              title: "Liberty Walk",
              path: "/bodykits/libertywalk",
            },
            {
              title: "Kamikazi",
              path: "/bodykits/kamikazi",
            },
            {
              title: "Top Secret",
              path: "/bodykits/topsecret",
            },
          ],
        },

        // {
        //   title: "Bodykits",
        //   active: false,
        //   path: "/coming-soon",
        // },

        {
          title: "Projects",
          active: false,
          path: `/projects`,
        },

        {
          title: "Cars",
          active: false,
          path: `/cars`,
        },

        {
          title: "Events",
          active: false,
          path: `/events`,
        },

        {
          title: "Services",
          active: false, // Whether the group is active or not
          action: "", // Icon for the group
          path: `/services`,
        },

        {
          title: "About us",
          active: false, // Whether the group is active or not
          action: "", // Icon for the group
          path: `/about-us`,
        },

        {
          title: "Contact",
          active: false, // Whether the group is active or not
          action: "", // Icon for the group
          path: `/contact-us`,
        },
      ],
      filterItems: [
        { label: "Bodykits", value: "BodyKit" },
        { label: "Projects", value: "Project" },
        { label: "Cars", value: "Car" },
        { label: "Events", value: "Event" },
        { label: "Services", value: "Service" },
      ],
      filters: ["BodyKit", "Project", "Car", "Event", "Service"],
      searchResults: [],
      loading: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    navigateBodykit(item) {
      window.location.href = `/bodykits/${item.title}`;
    },

    redirectTo(item) {
      // alert(item)
      this.$router.push(item);
    },

    navigate(path) {
      this.$router.push(path);
      this.menuDialog = false;
    },

    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    goTo(path) {
      this.$router.push({ path });
    },

    async search() {
      this.loading = true;
      try {
        const models = [...this.filters];
        const result = await fetchSearch(this.searchTerm, this.filters);
        let finalResult = [];
        for (let i = 0; i < models.length; i++) {
          if (result[i].length != 0) {
            let newResult = {
              title: models[i],
              items: result[i],
            };
            console.log(newResult);
            finalResult.push(newResult);
          }
        }
        // result.forEach((items) => {

        // });
        this.searchResults = finalResult;
      } catch (error) {
        // Handle errors from the fetchSearch call
        console.error("Error fetching search results:", error);
        // Optionally, set an error message for the user
      } finally {
        this.loading = false;
      }
    },
    debouncedSearch: debounce(function () {
      this.search();
    }, 800), // Adjust the delay (300ms) as needed

    searchItemClicked(item, model) {
      if (model === "BodyKit") {
        this.$router.push({ path: `/bodykits/${item.name}` });
      } else if (model === "Project") {
        this.$router.push({ path: `/project/${item.id}` });
      } else if (model === "Event") {
        this.$router.push({ path: `/events` });
      } else if (model === "Service") {
        this.$router.push({ path: `/services` });
      }

      this.searchDialog = false;
      // this.$router.push({ path });
    },
  },
};
</script>
<style>
/* #toolbar {
  padding-left: 200px;
  padding-right: 200px;
} */
</style>
