// require("./bootstrap");
// import Vue from "vue";
// import App from "./App.vue";
// import vuetify from "./plugins/vuetify";
// import axios from "./axios";
// import router from "./router";
// import store from "./store/store";
// import Vuebar from "vuebar";
// import "./plugins/base";
// import VueSkycons from "vue-skycons";
// import InstantSearch from "vue-instantsearch";
// import VueFeather from "vue-feather";
// import VueYouTubeEmbed from "vue-youtube-embed";

// Vue.use(VueFeather);
// Vue.use(VueSkycons, {
//   color: "#1e88e5",
// });
// Vue.use(InstantSearch);
// Vue.config.productionTip = false;
// Vue.use(Vuebar);
// Vue.use(VueYouTubeEmbed);
// new Vue({
//   vuetify,
//   store,
//   router,
//   axios,
//   render: (h) => h(App),
// }).$mount("#app");

require("./bootstrap");
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "./axios";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueFeather from "vue-feather";
import VueYouTubeEmbed from "vue-youtube-embed";
import { CIcon } from '@coreui/icons-vue';

Vue.use(VueFeather);
Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(VueYouTubeEmbed);
Vue.component('CIcon', CIcon);

new Vue({
  vuetify,
  store,
  router,
  axios,
  render: (h) => h(App),
  mounted() {
    // Create and append the viewport meta tag
    const viewportMeta = document.createElement('meta');
    viewportMeta.name = 'viewport';
    viewportMeta.content = 'width=device-width, initial-scale=1';
    document.head.appendChild(viewportMeta);
  }
}).$mount("#app");
