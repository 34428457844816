import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      name: "Landing",
      path: "/",
      // redirect: "/authentication/login",
      component: () => import("@/views/Landing/Landing"),
    },

    {
      name: "Landing",
      path: "/home",
      // redirect: "/authentication/login",
      component: () => import("@/views/Landing/Landing"),
    },

    {
      name: 'Landing2',
      path: '/home2',
      component: () => import("../src/views/Landing/Landing2.vue")
    },

    {
      name: 'Landing3',
      path: '/home3',
      component: () => import("../src/views/Landing/Landing3.vue")
    },

    {
      name: "Parts",
      path: "/parts",
      component: () => import("@/views/AutoParts/Parts"),
    },

    {
      name: "Part",
      path: "/parts/:name",
      component: () => import("@/views/AutoParts/Part"),
    },

    //Multiple Projects Route
    {
      name: "AllProjects",
      path: "/projects",
      component: () => import("@/views/BodyKits/Projects.vue"),
    },

    //Singple Project Route
    {
      name: "SingleProject",
      path: "/project/:id",
      component: () => import("@/views/BodyKits/Project.vue"),
    },

    //Multiple Cars Route
    {
      name: "AllCars",
      path: "/cars",
      component: () => import("@/views/BodyKits/Cars.vue"),
    },

    {
      name: "Car",
      path: "/cars/:name",
      component: () => import("@/views/BodyKits/Car.vue"),
    },

    {
      name: "CarProjects",
      path: "/car/projects/:name",
      component: () => import("@/views/BodyKits/CarProject.vue"),
    },

    {
      name: "Build",
      path: "/project/build/:name",
      // redirect: "/authentication/login",
      component: () => import("@/views/BodyKits/Builds.vue"),
    },

    {
      name: "BodyKits",
      path: "/bodykits/:name",
      // redirect: "/authentication/login",
      component: () => import("@/views/BodyKits/Bodykits.vue"),
    },

    {
      name: "BodyKit",
      path: "/bodykits/:name/:id",
      // redirect: "/authentication/login",
      component: () => import("@/views/BodyKits/Bodykit.vue"),
    },


    {
      name: "Builds",
      path: "/bodykit/:brand/:car",
      // redirect: "/authentication/login",
      component: () => import("@/views/BodyKits/NewBuilds.vue"),
    },

    // {
    //   name: 'Bodykits',
    //   path: "/bodykit/:id",
    //   component: () => import("@views/Bodykits/Bodykits.vue")
    // },

    {
      name: "Events",
      path: "/events",
      // redirect: "/authentication/login",
      component: () => import("@/views/Events/Events.vue"),
    },

    {
      name: "Event",
      path: "/event/:id",
      // redirect: "/authentication/login",
      component: () => import("@/views/Events/Event.vue"),
    },

    {
      name: "Services",
      path: "/services",
      // redirect: "/authentication/login",
      component: () => import("@/views/Services/Services.vue"),
    },


    {
      name: "About",
      path: "/about-us",
      // redirect: "/authentication/login",
      component: () => import("@/views/Landing/About.vue"),
    },

    {
      name: "Contact",
      path: "/contact-us",
      // redirect: "/authentication/login",
      component: () => import("@/views/Landing/Contact.vue"),
    },

    {
      name: "Services",
      path: "/services",
      // redirect: "/authentication/login",
      component: () => import("@/views/Services/Services.vue"),
    },

    {
      name: "RequestForm",
      path: "/request-form",
      // redirect: "/authentication/login",
      component: () => import("@/views/RequestForm/RequestForm"),
    },

    {
      name: "Products",
      path: "/products",
      component: () => import("@/views/Products/Products"),
    },

    {
      name: "ComingSoon",
      path: "/coming-soon",
      component: () => import("@/views/ComingSoon.vue"),
    },

    {
      path: "*",
      component: () => import("@/views/PageNotFound.vue"),
    }

  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
