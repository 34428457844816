<template>
  <v-app id="flexy" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`" style="background-color: #0a050b;">
    <!-- #57606a -->
    <ToolbarComponent title="Carbonzone" />
    <div class="main-content">
      <router-view />
    </div>

    <Footer v-if="!isPageNotFoundRoute" />
  </v-app>
</template>

<script>
import ToolbarComponent from "./components/ToolbarComponent.vue";
import Footer from "./views/Landing/Footer.vue";

export default {
  name: "App",
  components: { ToolbarComponent, Footer },

  computed: {
    // Check if the current route matches the "Page Not Found" route
    isPageNotFoundRoute() {
      return this.$route.matched.some(route => route.path === "*");
    }
  }
};
</script>

<style>
#flexy {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* padding: 16px; */
}
</style>

